//MAIN Menu left (uncollapsed) start
.p-panelmenu .p-panelmenu-header > a {
    padding: 1.25rem;
    border: unset;
    box-sizing: border-box;
    background-color: var(--darkblue-color);
    overflow: hidden;
    font-weight: 700;
    border-radius: unset;
    transition: box-shadow 0.2s;
}


.p-panelmenu .p-panelmenu-header.p-highlight > a {
    background-color: var(--lightblue-color) !important;

}

.p-element.p-panelmenu-header.p-highlight .p-menuitem-text,
.p-element.p-panelmenu-header.p-highlight .p-menuitem-icon{
    color: var(--orange-color)!important;
}


.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    box-sizing: border-box;
    background-color: var(--lightblue-color);
    overflow: hidden;
    transition: box-shadow 0.2s;
    -webkit-user-select: none;
    user-select: none;
}

.p-panelmenu .p-panelmenu-content {
    padding: 0px;
    border: unset;
    background: #ffffff;
    color: #495057;
    margin-bottom: 4px;
    border-top: unset;
    border-top-right-radius: unset;
    border-top-left-radius: unset;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: unset;
    background: #e9ecef;
    color: #343a40;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background-color: var(--orange-color) !important;
    border-color: unset;
    color: #343a40;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background-color: var(--orange-color) !important;
}

.p-menuitem.active {
    background-color: var(--orange-color) !important;
}

.p-menuitem-link-active{
    background-color: var(--orange-color) !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid var(--blue-color);
    border-width: 0 2px 1px 0;
    padding: 1rem 1rem;
}

.p-datatable .p-datatable-tbody > tr > td, .p-datatable .p-datatable-thead > tr > th {
    border-bottom: 5px solid var(--blue-color);
    border-right: 2px solid var(--blue-color);
}

.p-panelmenu-header .p-panelmenu-icon.pi-chevron-down,
.p-panelmenu-header .p-panelmenu-icon.pi-chevron-right{
    display:none;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem;
    background: var(--orange-color);
}

.p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0px;
}

.p-menuitem-icon.pi{
    font-size: 2.1rem;
}
//MAIN menu left (uncollapsed) ends
//MAIN menu tiered (collabsed) starts

.p-tieredmenu {
    padding: 0.25rem 0;
    box-sizing: border-box;
    background-color: var(--darkblue-color);
    //overflow: hidden;
    border: unset;
    border-radius: unset;
    width: 12.5rem;
}

.p-tieredmenu .p-menuitem-text{
    display: block;
}

.p-tieredmenu .p-submenu-list {
    position: absolute;
    padding: 0;
    min-width: 100%;
    z-index: 1;
    display: none;
    box-sizing: border-box;
    background-color: var(--darkblue-color);
    width:300px;
}

.p-tieredmenu .p-submenu-list > li:first-child a{
    background-color: var(--darkblue-color)!important;

}
.p-tieredmenu .p-submenu-list > li:first-child a span.p-menuitem-icon,
.p-tieredmenu .p-submenu-list > li:first-child a span.p-menuitem-text{
    color: var(--orange-color)!important;
}

.p-tieredmenu .main-menu .p-submenu-icon.pi-angle-right{
    display: none;
}

.p-tieredmenu .sub-menu-with-item .p-submenu-icon.pi-angle-right{
    display: block;
}

.p-panelmenu.p-component{
    height: 90vh!important;
    overflow-y: auto;
}

.p-menu.p-menu-overlay,
.p-menu .p-submenu-header {
    background: var(--lightblue-color);
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: var(--orange-color);
}

.p-menu .p-menu-separator {
    border-color: var(--orange-color);
}
/*
.p-tieredmenu .sub-menu-with-item .p-submenu-icon.pi-angle-right {
    display: block;
}
*/


//MAIN menu tiered (collabsed) ends
