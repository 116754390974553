.send-button {
    background-color: var(--orange-color)!important;
}

.p-toolbar .send-button{
    background-color: var(--orange-color)!important;
}

.button-style{
    background-color: var(--orange-color)!important;
    cursor: pointer !important;
    position: relative !important;
    margin-left: 10px !important;
    display: inline-block !important;
    padding: 1px 6px !important;
    line-height: 24px;

    border-radius: 0px !important;
    border: 1px solid var(--darkblue-color) !important;
    border-color: var(--darkblue-color) !important;
}

.button-style-round{
    border-radius: 4px !important;
    border: 1px solid var(--darkblue-color) !important;
    border-color: var(--darkblue-color) !important;
}

.button-style.success{
    background-color: var(--green-color)!important;
}

.button-style.warning{
    background-color: var(--yellow-color)!important;
}

.button-style.alert{
    background-color: var(--red-color)!important;
}

.button-single-icon-size-50{
    width: 50px;
    height: 50px;
}
.button-single-icon-size-40{
    width: 40px;
    height: 40px;
}
.button-single-icon-size-30{
    width: 30px;
    height: 30px;
}
.button-single-icon-size-20{
    width: 20px;
    height: 20px;
}
.button-single-icon-size-10{
    width: 10px;
    height: 10px;
}


body .user-management .p-element.delete-button button,
body .user-management .p-element.delete-button button:hover {
    background-color: var(--red-color) !important;
    border-color: var(--red-color) !important;
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

body .user-management .p-element.edit-button button {
    background-color: var(--orange-color) !important;
    border-color: var(--lightblue-color) !important;
    width: 30px;
    height: 30px;
}

body .user-management .p-element.edit-button button:hover,
body .user-management .p-element.delete-button button:hover{
    border-color: var(--lightblue-color) !important;
}

.p-toolbar.p-component p-button.send-button button.p-button.p-component{
    background-color: var(--orange-color);
}

.p-toolbar.p-component p-button.send-button.inactive button.p-button.p-component{
    background-color: var(--blue-color)!important;
    border-color: var(--blue-color)!important;
}

.p-toolbar p-button.send-button.inactive{
    background-color: var(--blue-color)!important;
}

.pi.pi-times-circle.success {
    color: #72aa00 !important;
    font-size: 1.5vw;
}

.pi.pi-check-circle.error {
    color: rgba(246, 76, 76, 0.8) !important;
    font-size: 1.5vw;
}

.p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.7rem;
}

.p-button.p-button-sm.send-button.left {
   margin-left:10px;
}

.p-button.p-button-sm.send-button.right {
    margin-right:10px;
}
