/* You can add global styles to this file, and also import other style files */
/*
body .ui-table .ui-table-thead > tr > th {
    padding: 0.571em 0.857em;
    border: none;
    font-weight: 700;
    color: #333333;
    background-color: unset;
}

body .ui-table .ui-table-tbody > tr > td {
    background-color: var(--table-light);
    border: unset;
    transition: box-shadow 0.2s;
}

body .p-dialog .p-dialog-content {
    background-color: unset;
    color: #333333;
    border: unset;
    padding: 0.571em 1em;
}
*/
.ui-dropdown-label-container {
    width: 100%;
}

.ui-button.ui-widget.ui-state-default.ui-button-text-only.ng-star-inserted.ui-state-disabled {
    opacity: 0.5;
}

.ui-sortable-column.ui-state-highlight {
    /*background-color: var(--orange-color)!important;*/
}


.quarterly-dashboard .ui-inputnumber-buttons-horizontal {
    top: 12px;
    position: relative;
}

.quarterly-dashboard button.ui-inputnumber-button-down {
    border-radius: 6px 0px 0px 6px !important;
}

.quarterly-dashboard button.ui-inputnumber-button-up {
    border-radius: 0px 6px 6px 0px !important;
}

.quarterly-dashboard .send-button {

    background-color: var(--lightorange-color);
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    border-color: var(--darkblue-color);
    border: 1px solid var(--darkblue-color);
    margin-left: 10px;
    display: inline-block;
    padding: 4px !important;

}

.quarterly-dashboard-table td.warning {
    background: var(--yellow-color);
    color: var(--table-dark) !important;
}

.quarterly-dashboard-table td.success {
    background: var(--green-color);
}

.quarterly-dashboard-table td.error {
    background: var(--red-color);
}


.quarterly-dashboard-table.content-table tr:nth-child(even) td.warning {
    background: var(--yellow-color);
    color: var(--table-dark) !important;
}

.quarterly-dashboard-table.content-table tr:nth-child(even) td.success {
    background: var(--green-color);
}

.quarterly-dashboard-table.content-table tr:nth-child(even) td.error {
    background: var(--red-color);
}


.quarterly-dashboard-table .pi-sort-alt:before {
    display: none;
}

.transaction-log-table .ui-table-summary {
    position: absolute !important;
    bottom: 25px;
    z-index: 1000;
    width: 100%;
}

.transaction-log-table .ui-table-summary p-paginator {
    position: unset;
    bottom: unset;
    z-index: unset;
    width: unset;
}

.transaction-log-table .ui-table-summary p-paginator .ui-paginator-current,
.transaction-log-table .ui-table-summary p-paginator p-dropdown {
    float: left;
}

.transaction-log-table .ui-table-summary p-paginator p-dropdown {
    margin-right: 10px;
}

.transaction-log-table .ui-table-summary p-paginator .ui-paginator-current {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 3px;
}

.ui-inputtext.input-box-light {
    background: var(--table-light) !important;
    border: 1px solid var(--darkblue-color) !important;
    padding: 5px 10px !important;
    height: 26px;
}

body .ui-table-summary .ui-widget button.send-button {
    background-color: var(--lightorange-color) !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    position: relative !important;
    border: 1px solid var(--darkblue-color) !important;
    border-color: var(--darkblue-color) !important;
    padding: 1px 6px !important;
    margin-left: 10px !important;
    display: inline-block !important;
}

body .ui-table-summary .ui-widget button.send-button:hover {
    background-color: var(--orange-color) !important;
    border-color: var(--darkblue-color) !important;
}

body .ui-inputgroup.password-group input {
    background-color: var(--darkblue-color) !important;
    border: 1px solid var(--blue-color) !important;
    border-color: var(--blue-color) !important;
    padding: 5px 10px !important;
}

body .m-subheader p-breadcrumb span {
    display: unset;
    float: unset;
    line-height: unset;
    font-size: 22px;
}

body .ui-sortable-column-icon.pi.pi-fw.pi-sort-alt {
    display: none;
}

.m-subheader span.calendar-input-field,
.m-subheader span.calendar-input-field span {
    line-height: 20px;
}

.p-toolbar .button-clear-input:hover {
    border: 1px solid var(--table-light) !important;
}

.p-toolbar .button-clear-input {
    border: 1px solid var(--table-light) !important;
    background-color: var(--terminal-inner-color) !important;
    cursor: pointer !important;
    border-radius: 0 !important;
    position: relative !important;
    display: inline-block !important;
    line-height: 14px;
}


.p-toolbar .send-button {
    background-color: var(--lightorange-color) !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    position: relative !important;
    border: 1px solid var(--darkblue-color) !important;
    border-color: var(--darkblue-color) !important;
    margin-left: 10px !important;
    display: inline-block !important;
    padding: 1px 6px !important;
    line-height: 24px;
}

.p-toolbar .send-button.alert {
    background-color: var(--red-color) !important;
    margin-bottom: 0px !Important;
}

.p-toolbar .send-button.warning {
    background-color: var(--orange-color) !important;
}

.p-toolbar .send-button.success {
    background-color: var(--green-color) !important;
}

.p-toolbar .send-button:disabled {
    background-color: grey !Important;
}

body .p-toolbar .ui-dropdown {
    width: unset !important;
    border-color: var(--terminal-inner-color);
}

.m-subheader span {
    display: block;
    float: left;
    line-height: 38px;
}

body .p-toolbar-group-left {
    margin-left: 25px;
    padding-bottom: 4px;
}

body .p-datatable-footer .p-toolbar-group-left {
    margin-left: 0px;
    padding-bottom: 0px;
}


body .dashboard-view .p-toolbar-group-left {
    margin-left: 0px;
}

.ui-dropdown-panel .ui-dropdown-item-group {
    font-weight: bold;
    cursor: default;
    background: var(--darkblue-color);
    padding: 0em .25em;
}

.ui-dropdown-panel .ui-dropdown-items-wrapper {
    overflow: auto;
    border-bottom: 1px solid var(--orange-color);
}

.ui-dropdown-panel .ui-dropdown-list {
    padding: 0em;
}

.dialog-with-footer.dashboard-dialog .ui-card-title {
    font-size: 1em;
}


.dialog-with-footer.dashboard-dialog .ui-table table {
    width: auto;
}


.art-details-dialog {
    width: 90%;
}

.art-details-terminal-dialog {
    width: 80%;
}

.art-details-dialog .pi.pi-check-circle.success,
.art-details-dialog .pi.pi-times-circle.error {
    font-size: 0.8vw;
}

body .p-toolbar .p-toolbar-group-left .ui-dropdown .ui-widget.ui-widget-content {
    max-width: 350px !important;
}

body .p-toolbar .p-toolbar-group-left .ui-dropdown-panel .ui-dropdown-filter-container {
    width: 350px !important;
}

.money-service-list-entry {
    cursor: pointer;
}

.pi.pi-comment.services {
    margin-left: 10px;
}

//.m-content.pdf-converter
#toolbarViewer {
    background-color: var(--table-light) !important;
}

#scaleSelectContainer select {
    background: var(--darkblue-color) !important;
}

.content-table.transaction-log-search .ui-table-scrollable-body {
    height: calc(100% - 0px);
}

.ui-inputtext:disabled {
    background-color: grey !Important;
}

.dashboard-downtime.p-toolbar .ui-inputswitch {
    margin-bottom: -7px;
}

.bottombar-websocket-error.ui-widget.ui-widget-content {
    border: 5px solid var(--red-color);
    border-color: var(--piggy-pink-color);
    border-radius: 0;
    background-color: var(--red-color);
    overflow: hidden;
}

p-dialog .ui-widget.ui-widget-content.current-operator-dialog {
    max-width: 80%;
    min-width: 50% !important;
    max-height: 80%;
}

.ui-widget.ui-widget-content.dashboard-widget-money-service-dialog {
    max-width: 70%;
    min-width: 50% !important;
    max-height: 80%;
}

.ui-widget.ui-widget-content.dashboard-widget-current-operator-dialog {
    max-width: 80%;
    min-width: 50% !important;
    max-height: 80%;
}

.ui-widget.ui-widget-content.dashboard-widget-terminal-issues-dialog {
    max-width: 70%;
    min-width: 50% !important;
    max-height: 80%;
}

p-card .card-with-link .ui-card-body {
    border: solid 2px var(--orange-color);
}

p-card .card-with-link .ui-card-body:hover {
    border: solid 2px white;
    cursor: pointer;
}

p-card .card-with-sub-link .ui-card-body .card-with-link {
    border: solid 2px var(--orange-color);
}

p-card .card-with-sub-link .ui-card-body .card-with-link:hover {
    border: solid 2px white;
    cursor: pointer;
}

p-dialog .ui-widget.ui-widget-content.transaction-dialog-legend {
    max-width: 350px !important;
    min-width: 350px !important;
}

p-dialog .ui-widget.ui-widget-content.chip-turnover-modal {
    max-width: 80%;
    min-width: 50% !important;
    max-height: 80%;
}

.table-view.logs p-paginator {
    bottom: 92px;
}

.table-view.logs.transaction p-paginator {
    bottom: 6px !important;
}

.payment-reports.p-toolbar .ui-inputswitch {
    margin-bottom: -7px;
}

.m-content p-table.content-table.payment-customer .ui-table.ui-widget {
    height: calc(100% - 240px) !important;
}

.map-view {
    position: relative;
    width: calc(100% - 16px);
    height: calc(100% - 64px);
    //border: 6px solid var(--darkblue-color)!important;
    cursor: grab;
    user-select: none;
    overflow-x: auto;
}

#map-view {
    background-color: #04041b;
}

.map-buttons {
    position: relative;
    bottom: 0;
    display: block;
    margin: 2px;
    width: 32px;
    height: fit-content;
    font-size: 20px;
    background-color: transparent;
    border: 2px solid var(--orange-color) !important;
    border-radius: 4px;

}

.copy-right {
    position: absolute;
    bottom: 12px;
    right: 12px;
}

.marker-tooltip {
    width: fit-content;
    background-color: var(--darkblue-color);
    padding-left: 16px;
    position: relative;
}

.map-buttons:hover {
    cursor: pointer;
    background-color: var(--orange-color) !important;
}

.map-buttons span {
    font-size: 20px;
}

.hidden {
    display: none;
}

.color-board {
    position: absolute;
    width: 200px;
    z-index: 10;
    bottom: 42px;
    left: 16px;
}

.color-board-title {
    position: absolute;
    z-index: 1;
    bottom: 22px;
    left: 16px;
    font-size: 12px;
}

.gradient-color-side {
    width: 100%;
    display: inline-flex;
}

.gradient-color-side div {
    width: 40px;
}

.gradient-info-side {
    width: 100%;
    display: inline-flex;
}

.gradient-info-side div{
    width: 40px;
}

.gradient-info-side span{
    font-size: 12px;
}

.gradient-color-side .blue-deg {
    background-color: var(--blue-color);
    border: 2px solid var(--blue-color) !important;
    opacity: 0.4;
}

.gradient-color-side .green-deg {
    background-color: var(--green-color);
    border: 2px solid var(--green-color) !important;
    opacity: 0.4;
}

.gradient-color-side .yellow-deg {
    background-color: var(--yellow-color);
    border: 2px solid var(--yellow-color) !important;
    opacity: 0.4;
}

.gradient-color-side .orange-deg {
    background-color: var(--orange-color);
    border: 2px solid var(--orange-color) !important;
    opacity: 0.4;
}

.gradient-color-side .red-deg {
    background-color: var(--red-color);
    border: 2px solid var(--red-color) !important;
    opacity: 0.4;
}

.delete-filter {
    position: absolute;
    top: 9px;
    font-size: 14px;
    right: 2px;
    cursor: pointer;
    color: var(--terminal-inner-color);
}

.content-table.payment-customer .ui-table-scrollable-view {
    height: calc(100% - 167px);
}

.component-overview-view .ui-table-scrollable-body {
    max-height: calc(100vh - 414px);
}

.component-overview-view .ui-tabview-panel.ui-widget-content {
    height: calc(100vh - 286px);
}

body .ui-inputswitch.ui-state-disabled {
    background-color: grey;
}

body .ui-inputswitch.ui-inputswitch-checked.ui-state-disabled .ui-inputswitch-slider {
    background-color: grey;
}

.download-menu .ui-menuitem:hover {
    background-color: var(--blue-color) !important;
}

.disabled-download-button button.ui-button {
    background-color: grey !important;
}

p-dialog .ui-widget.ui-widget-content.addOperator-dialog {
    max-width: 75vw !important;
    min-width: 75vw !important;
    max-height: 80vh !important;
    min-height: 80vh !important;
    height: 100%;
}

p-dialog .addOperator-dialog .p-dialog-footer {
    background: var(--blue-color) !important;
    padding: 0em;
}

p-dialog .addOperator-dialog p-card .ui-widget.ui-widget-content {
    max-width: unset !important;
    min-width: unset !important;
    max-height: unset !important;
    border: unset !important;
}

p-dialog .addOperator-dialog p-card .ui-dropdown {
    margin-left: 0px !important;
    margin-top: 5px;
}

p-dialog .addOperator-dialog p-card input:disabled {
    background-color: grey !important;
    border: 1px solid grey !important;
}

p-dialog .addOperator-dialog .p-dialog-content.ui-widget-content {
    height: 100%;
}

.terminal-chooser-container p-listbox div.ui-listbox {
    width: 100%;
}

.dashboard-terminals-connected-widget-dialog .terminals-disconnected-table .ui-card-body {
    background-color: var(--red-color) !important;
}

.dashboard-terminals-connected-widget-dialog .terminals-connected-table .ui-card-body {
    background-color: var(--green-color) !important;
}

p-dialog .transaction-dialog-view.ui-widget.ui-widget-content {
    max-width: unset;
    min-width: unset;
    max-height: unset;
}

p-dialog .transaction-dialog-view.ui-widget.ui-widget-content .transaction-pic-type-check {
    width: 20px;
    height: 20px;
    background-color: var(--mint-color);
    border-radius: 7px;
    margin-left: 1% !important;
    margin-right: 1% !important;
}

p-dialog .transaction-dialog-view.ui-widget.ui-widget-content .transaction-pic-type-transaction-pic {
    width: 20px;
    height: 20px;
    background-color: var(--green-color);
    border-radius: 7px;
    margin-left: 1% !important;
    margin-right: 1% !important;
}

p-dialog .transaction-dialog-view.ui-widget.ui-widget-content .transaction-pic-type-invoice {
    width: 20px;
    height: 20px;
    background-color: var(--yellow-color);
    border-radius: 7px;
    margin-left: 1% !important;
    margin-right: 1% !important;
}

p-dialog .transaction-dialog-view.ui-widget.ui-widget-content .transaction-pic-type-id-scan {
    width: 20px;
    height: 20px;
    background-color: var(--red-color);
    border-radius: 7px;
    margin-left: 1% !important;
    margin-right: 1% !important;
}

p-dialog .transaction-dialog-view.ui-widget.ui-widget-content .transaction-pic-previous,
p-dialog .transaction-dialog-view.ui-widget.ui-widget-content .transaction-pic-next,
div.transaction-dialog-view.ui-widget.ui-widget-content .transaction-pic-previous,
div.transaction-dialog-view.ui-widget.ui-widget-content .transaction-pic-next {
    width: 20px;
    height: 20px;
    background-color: var(--white-color);
    border-radius: 7px;
    margin-left: 1% !important;
    margin-right: 1% !important;
    color: var(--darkblue-color)
}


body .ui-widget .add-computer-registration-button button,
body .ui-widget .add-computer-registration-button button:hover {
    background-color: var(--green-color) !important;
    border-color: var(--green-color) !important;
    width: 40px;
    float: right;
}

body .ui-widget .remove-computer-registration-button button,
body .ui-widget .remove-computer-registration-button button:hover {
    background-color: var(--red-color) !important;
    border-color: var(--red-color) !important;
    width: 40px;

    float: left;
}

body .computer-serial .ui-widget .delete-button button,
body .computer-serial .ui-widget .delete-button button:hover {
    background-color: var(--red-color) !important;
    border-color: var(--red-color) !important;
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

body .computer-serial .ui-widget .edit-button button {
    background-color: var(--orange-color) !important;
    border-color: var(--lightblue-color) !important;
    width: 30px;
    height: 30px;
}

body .computer-serial .ui-widget .edit-button button:hover {
    border-color: var(--lightblue-color) !important;
}

body .computer-serial-registration-delete-confirmation-toast .ui-toast-message-content .ui-button-delete {
    background-color: var(--red-color) !important;
    border-color: var(--red-color) !important;
}

body .computer-serial-registration-delete-confirmation-toast .ui-toast-message-content .ui-button-delete:hover {
    background-color: var(--red-color) !important;
    border-color: var(--red-color) !important;
    border: 1px solid var(--red-color) !important;
}

body .computer-serial-registration-delete-confirmation-toast .ui-toast-message-content .ui-button-reject {
    background-color: var(--orange-color) !important;
}

p-card .terminal-lifecycle-testrun-card .ui-card-body {
    background-color: var(--green-color) !important;
}

.ui-toast-center {
    left: 50%;
    top: 50%;
    min-width: 30vw;
    transform: translate(-50%, -50%);
}

textarea.payers-address {
    width: 100%;
}

p-button.edit-button.touch button,
p-button.delete-button.touch button,
p-button.print-button.touch button {
    width: 45px;
}

p-button.print-button.touch button {
    margin-left: 10px;
}


.m-content.terminal-view-icon .ui-scrollpanel-content {
    height: calc(100% + 6px) !important;
}

signature-pad canvas {
    background: whitesmoke;
}

p-dialog .transaction-dialog-view p-card .ui-widget.ui-widget-content {
    max-width: unset !important;
    min-width: unset !important;
    max-height: unset !important;
}

.ui-datepicker-calendar-container span.ui-state-disabled {
    background: var(--blue-color);
}

body .ui-datepicker table.ui-datepicker-calendar td:not(.ui-state-disabled) a.ui-state-highlight {
    background-color: var(--orange-color) !important;
}

.ui-datepicker-calendar-container .ui-datepicker-other-month {
    cursor: not-allowed !important;
}

.ui-datepicker-calendar-container td:not(.ui-datepicker-other-month) {
    cursor: pointer;
}

td.status-not-configured {
    background: var(--babyblue-color);
}

td.status-ok {
    background: var(--green-color);
}

td.status-warning {
    background: var(--yellow-color);
    color: (#000000) !important;
}

td.status-error {
    background: var(--red-color);
}

.terminal-feauture-dialog-view {
    min-width: 1000px !important;
}


.content-table {
    height: calc(100% - -28px) !important;
}

.m-body .m-content.table-view.configuration-change-log-table {
    padding: 15px 30px 0px 30px !important;
    height: 100%;
    overflow: inherit;
    position: relative;
}

body .jackpot-forms-list .p-element.delete-button button,
body .jackpot-forms-list .p-element.delete-button button:hover {
    background-color: var(--red-color) !important;
    border-color: var(--red-color) !important;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

body .jackpot-forms-list .p-element.edit-button button {
    background-color: var(--orange-color) !important;
    border-color: var(--lightblue-color) !important;
    width: 50px;
    height: 50px;
}

body .jackpot-forms-list .p-element.edit-button button:hover {
    border-color: var(--lightblue-color) !important;
}

body .jackpot-forms-list .p-element.history-button button {
    background-color: var(--orange-color) !important;
    border-color: var(--lightblue-color) !important;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

body .jackpot-forms-list .p-element.history-button button:hover {
    border-color: var(--lightblue-color) !important;
}

.p-toolbar .send-button.disabled {
    background-color: grey !important;
}

.full-width-button {
    width: 100% !important;
    height: 100% !important;
}

p-toast button.ui-button.full-width-button {
    width: 100% !important;
    height: 100% !important;
}

.m-dropdown.m-dropdown--header-bg-fill .m-dropdown__header .m-dropdown__header-subtitle.notification {
    display: block !important;
}

.nav-tabs.notification .nav-item.show .nav-link,
.nav-tabs.notification .nav-link.active {
    background-color: var(--orange-color) !important;
    padding: 10px 10px 14px 10px;
}


.m-badge.m-badge--wide-logs {
    letter-spacing: unset !important;
    padding: 0px 5px !important;
}

#paid-as-cash-positive span input {
    color: var(--green-color) !important;
    font-size: 20px;
    font-weight: bold;
}

#paid-as-cash-negative span input {
    color: var(--red-color) !important;
    font-size: 20px;
    font-weight: bold;
}

#jackpot-amount span input {
    font-size: 20px;
    font-weight: bold;
}

.dialog-with-footer.jackpot-general {
    width: 600px;
}

.dialog-with-footer.jackpot-documents {
    width: 800px;
}

.dialog-with-footer.view-jackpot table td.tb-left {
    text-align: left;
}

.dialog-with-footer.view-jackpot table td.tb-right {
    text-align: right;
}

.dialog-with-footer.view-jackpot table tr:nth-child(even) {
    background-color: var(--lightblue-color);
}

.dialog-with-footer.view-jackpot table td.width-60 {
    width: 60%;
    padding-right: 20px;
}


.dialog-with-footer.view-jackpot p-card .card-header-42.ui-card {
    margin-top: 42px;
}


label.checkbox-label-readonly {
    color: grey !important;
}


.notifications-counter-logs {
    background-color: var(--lightorange-color);
    border-radius: 25%;
    height: 18px;
    width: 18px;
    position: absolute;
    bottom: 0;
    right: 30px;
    z-index: 1;
    display: none;
}

.notifications-counter-logs.bigger-size {
    border-radius: 25%;
    width: 26px;
    position: absolute;
    bottom: 0;
    right: 24px;
    z-index: 1;
    display: none;
}

.notifications-counter-logs.middle-size .centerrelative {
    left: 9%;
    top: -6%;
}

.notifications-counter-logs .centerrelative {
    left: 27%;
    top: -6%;
}

.notifications-counter-logs.bigger-size .centerrelative {
    left: 4%;
    top: -6%;
}

.notifications-counter-logs {
    display: block !important;
}

.notifications-counter {
    border-radius: 25%;
    height: 18px;
    width: 18px;
    position: absolute;
    bottom: 0;
    right: -6px;
    z-index: 1;
    display: none;
}

.notifications-counter.bigger-size {
    border-radius: 25%;
    height: 18px;
    width: 26px;
    position: absolute;
    bottom: 0;
    right: -13px;
    z-index: 1;
    display: none;
}

.notifications-counter {
    right: -10px !important;
}

.notifications-counter {
    display: block !important;
}

.m-dropdown.m-dropdown--arrow .m-dropdown__arrow, .m-dropdown.m-dropdown--arrow.m-dropdown--up .m-dropdown__arrow {
    position: absolute;
    line-height: 0;
    display: inline-block;
    overflow: hidden;
    height: 11px;
    width: 40px;
    position: relative;
    left: 50%;
    margin-left: -9px !important;
    top: 0;
    position: absolute;
}

#m_header_topbar .m-dropdown.m-topbar__notifications.m-dropdown--align-center.m-dropdown--large .m-dropdown__wrapper {
    top: 20px !important;
    left: 61px !important;
    height: auto !important;
    width: 300px !important;
}

.ui-contextmenu .ui-menuitem-link.ui-state-disabled {
    background: grey !important;
}

.ui-inputtext.error {
    border-color: red !important;
}

body .ui-inputgroup.password-group #password_confirm.error {
    border-color: red !important;
}

.tax-calculation.ui-inputswitch.ui-widget {
    margin-bottom: -7px;
}

.p-dialog .p-dialog-titlebar-icons {
    display: none;
}


.toast-message-modal.ui-toast-center {
    min-width: unset !important;
    z-index: 100000001;
}

.toast-message-modal.ui-toast-center .ui-toast-message {
    background-color: var(--table-dark);
}

p-toast .toast-message-modal.ui-toast-center button.ui-button {
    width: 100% !important;
}

.alert span {
    color: var(--red-color) !important;
}

.jackpot-general .p-toolbar .send-button,
.jackpot-W2G .p-toolbar .send-button,
.jackpot-1042 .p-toolbar .send-button,
.view-jackpot .p-toolbar .send-button,
.jackpot-history .p-toolbar .send-button {
    padding: 8px !important;
}

.ui-toast-top-right {
    height: 50% !important;
}

.m-aside-menu .m-menu__nav .m-menu__item .m-menu__submenu .m-menu__item--parent ul li.m-menu__item--active,
.m-aside-menu .m-menu__nav .m-menu__item .m-menu__submenu .m-menu__item--parent ul li:hover.m-menu__item.disabled {
    background-color: rgba(234, 76, 4, 0.4) !important;
}

.m-aside-menu .m-menu__nav .m-menu__item .m-menu__submenu .m-menu__item--parent ul li.m-menu__item.disabled a {
    cursor: default;
}

.m-aside-menu .m-menu__nav .m-menu__item .m-menu__submenu .m-menu__item--parent ul li.m-menu__item.disabled a span {
    color: grey !important;
}

.change-view-style > div.disabled {
    cursor: default;
}

.change-view-style .list-view.disabled:hover {
    background-image: url(/assets/app/media/img/icons/grid-list.png);
}

.change-view-style .grid-view.disabled:hover {
    background-image: url(/assets/app/media/img/icons/grid-tile.png);
}

.change-view-style .fill-level-view.disabled:hover {
    background-image: url(/assets/app/media/img/icons/stats-icon.png);
}

.alert {
    background: var(--red-color) !important;
}

.alert span {
    color: #fff !important;
}

body .p-toolbar.map-view-toolbar .p-toolbar-group-left .ui-dropdown.dropdown-map-art-id .ui-widget.ui-widget-content {
    max-width: 550px !important;
}

body .p-toolbar.map-view-toolbar .p-toolbar-group-left .dropdown-map-art-id .ui-dropdown-panel .ui-dropdown-filter-container {
    width: 550px !important;
}

body .p-toolbar.map-view-toolbar .p-toolbar-group-left .ui-dropdown .ui-dropdown-label-container {
    // max-width: 350px!important;
}

.snapshot img {
    text-align: center;

    max-width: 100%;
    max-height: 100%;

}

/*
.p-component, .p-component * {
    box-sizing: border-box;
    background-color: var(--darkblue-color);
    //border-color: var(--blue-color);
    //border: 5px solid var(--blue-color);
    //border-radius: 0;
    overflow: hidden;
}
*/

//Prime 14 Update
.p-card.p-component,
.m-content .p-toolbar.p-component,
.p-button.p-component,
.p-contextmenu.p-component,
.p-sidebar-content,
.p-dropdown,
.p-dropdown-panel .p-dropdown-header,
.p-calendar .p-datepicker,
.p-datepicker .p-datepicker-header,
.p-datepicker .p-datepicker-calendar-container,
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header,
.p-datepicker .p-timepicker,
.p-breadcrumb.p-component,
.p-datatable .p-datatable-footer,
.p-datatable .p-datatable-thead > tr > th,
.p-tabview .p-tabview-nav,
.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-panels,
.p-listbox.p-component,
.p-listbox .p-listbox-header,
.p-listbox .p-listbox-list .p-listbox-item.p-highlight,
.p-tieredmenu.p-tieredmenu-overlay,
.p-steps .p-steps-item .p-menuitem-link .p-steps-number,
.p-treetable .p-treetable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td,
.tooltipClass,
.p-tree.p-component.p-tree-selectable,
.terminal-confirmation-toast.p-toast .p-toast-message.p-toast-message-warn,
.p-password-panel{
    box-sizing: border-box;
    background-color: var(--lightblue-color);
    overflow: hidden;
}

.p-tooltip-text.p-component {
    //width:500px!important;
    //height:500px!important;
}

.p-tooltip {
    min-width: 500px !important;
    //height:500px!important;
}
.p-tooltip,
.p-tooltip.p-component,
.p-tooltip-text,
.p-tooltip-text.p-component,
.p-tooltip .p-tooltip-text{
    background-color: var(--darkblue-color) !important;
    border-radius: 6px;
}

.tooltipClass {

    //width: 600px!important;
}

.p-slider.p-component.p-slider-horizontal {
    background-color: var(--darkblue-color) !important;
}

.p-slider-range {
    background-color: var(--lightorange-color) !important;
}

.p-slider-handle {
    background: var(--orange-color) !important;
}

.p-dropdown-items-wrapper {
    background-color: var(--darkblue-color);
}

.p-scrollpanel .p-scrollpanel-bar {
    background: var(--orange-color);
}

.p-sidebar-header,
.p-sidebar-footer {
    box-sizing: border-box;
    background-color: var(--darkblue-color);
    overflow: hidden;
}

.m-content.table-view.component-overview-view .p-card.p-component,
.m-content.table-view.component-overview-view .p-card.p-component .p-tabview-nav {
    background-color: var(--blue-color);
    border: none;
}

.m-content.table-view.component-overview-view .p-card {
    box-shadow: none;
}

.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
    border-bottom: 5px solid var(--blue-color);
}

.p-toolbar.p-component,
.m-content.terminal-view-icon .p-toolbar.p-component,
.m-content.terminal-view-list .p-toolbar.p-component,
.m-content.terminal-view-fill .p-toolbar.p-component, {
    padding: 5px;
    border: none;
    background: none;
    background-color: unset!important;
}

.p-dialog .p-toolbar.p-component,
.p-datatable .p-datatable-header,
.p-breadcrumb.p-component {
    background: none;
    border: none;
}

.p-dialog .p-dialog-content {
    background-color: var(--blue-color);
    padding: 10px 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-header,
.p-dialog .p-dialog-footer {
    background-color: var(--lightblue-color);
    padding: 5px;
}

.p-button {
    padding: 5px 10px;
}

.p-toolbar .p-button {
    padding: 5px 10px;
}

.p-button.p-component {
    border-color: var(--orange-color) !important;
}

.p-button.p-component.p-highlight {
    background-color: var(--orange-color);
}

.p-button.p-component.p-highlight:hover {
    background-color: var(--lightorange-color);
}

.p-button:focus {
    box-shadow: none;
}

.p-button:enabled:active {
    background-color: var(--orange-color);
}

.p-button:enabled:hover {
    background-color: var(--lightorange-color);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--green-color);
}

.p-inputswitch .p-inputswitch-slider {
    background: var(--red-color);
}

.dashboard-view .p-col-nogutter {
    padding: 5px;
}

.p-sidebar-right {
    width: 40rem !important;
}

.ui-sidebar-lg .p-sidebar-right {
    width: 50% !important;
}

.p-sidebar-right .p-sidebar-content {
    height: 100%;
}

.p-datatable .p-datatable-tbody > tr {
    background: none;
}

.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight:hover,
.p-datatable .p-sortable-column:not(.p-highlight):hover,
.p-treetable .p-sortable-column:not(.p-highlight):hover,
.p-treetable .p-sortable-column.p-highlight,
.p-treetable .p-sortable-column.p-highlight:hover, {
    background: var(--orange-color);
}

.p-datatable-flex-scrollable {
    height: 87% !important;
}

.money-service-list .p-datatable-flex-scrollable {
    height: calc(100vh - 360px)!important;
}

.p-dropdown {
    border: none
}

.p-dropdown:not(.p-disabled) {
    border: 1px solid var(--darkblue-color);
    background: var(--darkblue-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: var(--orange-color) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: var(--lightorange-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    background: var(--table-light)
}

.p-datepicker table td.p-datepicker-today > span {
    background: var(--table-light)
}

.p-datepicker table td > span.p-highlight {
    background: var(--orange-color) !important;
}

.p-datepicker .p-datepicker-header {
    border-bottom: 1px solid var(--blue-color);
}

.p-datepicker .p-timepicker {
    border-top: 1px solid var(--blue-color);
}

.p-datepicker-other-month .p-disabled {
    opacity: 0.2;
}

.p-inputtext {
    padding: 4px;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover,
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background-color: var(--lightorange-color);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: var(--orange-color) !important;
}

.dashboard-terminals-connected-widget-dialog .terminals-disconnected-table .p-card-body {
    background-color: var(--red-color) !important;
}

.dashboard-terminals-connected-widget-dialog .terminals-connected-table .p-card-body {
    background-color: var(--green-color) !important;
}

.dashboard-terminals-connected-widget-dialog .terminals-connected-table .p-card-body .p-datatable .p-datatable-tbody > tr,
.dashboard-terminals-connected-widget-dialog .terminals-disconnected-table .p-card-body .p-datatable .p-datatable-tbody > tr {
    background: none;
}

.dashboard-terminals-connected-widget-dialog .terminals-connected-table .p-card-body .p-datatable .p-datatable-tbody > tr > td,
.dashboard-terminals-connected-widget-dialog .terminals-disconnected-table .p-card-body .p-datatable .p-datatable-tbody > tr > td {
    border: none;
    padding: 5px;
}

.dashboard-terminals-connected-widget-dialog .terminals-connected-table .p-card-body .p-datatable .p-datatable-thead > tr > th,
.dashboard-terminals-connected-widget-dialog .terminals-disconnected-table .p-card-body .p-datatable .p-datatable-thead > tr > th {
    background: none;
    border: none;
    padding: 5px;
}

.p-listbox {
    border: 1px solid var(--darkblue-color);
}

.p-listbox .p-listbox-header {
    border-bottom: none;
}

.p-checkbox {
    margin: 0px 0px 10px 0px;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--orange-color);
    background: var(--orange-color);
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: var(--lightorange-color);
}

p-paginator .ui-paginator-bottom {
    box-sizing: border-box;
    background-color: transparent;
    overflow: hidden;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-color: var(--orange-color);
    background: var(--orange-color);
}


.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--lightorange-color);
    background: var(--lightorange-color);
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    border-color: var(--orange-color);
    background: var(--orange-color);
}

.p-steps .p-steps-item .p-menuitem-link {
    background: none;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    border-color: var(--darkblue-color);
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    border-color: var(--orange-color);
    background: var(--orange-color);
}


.steps-custom.p-steps.p-component {
    margin: 0px 0px 10px 0px;
}

.p-steps .p-steps-item:before {
    border-top: none;
}

.p-sidebar-top {
    height: 19rem !important;
}

.p-sidebar-header {
    font-size: 1.5rem;
}

.p-toast .p-toast-message.p-toast-message-success {
    background-color: var(--green-color) !important;
}

.p-toast .p-toast-message.p-toast-message-warning {
    background-color: var(--orange-color) !important;
}

.p-toast .p-toast-message.p-toast-message-error {
    background-color: var(--red-color) !important;
}

p-toast .operator-delete-toast h3{
    background-color: transparent!important;
    --text-color: var(--orange-color);
}

p-toast .operator-delete-toast p{
    --text-color: var(--orange-color);
}

p-toast .operator-delete-toast i{
    --text-color: var(--orange-color);
}

p-toast .operator-delete-toast button.p-toast-icon-close .p-toast-icon-close-icon {
    color: var(--orange-color) !important;
}

.p-scrollpanel .p-scrollpanel-bar {
    background-color: var(--orange-color) !important;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-top: 9px;
}

.pi {
    font-size: 1.1rem;
}

.p-calendar .p-inputtext {
    flex: 1 1 auto;
    width: 100% !important;
}

.p-card.card-with-link,
.p-card.card-with-sub-link .p-card-content .card-with-link {
    border: 2px solid var(--orange-color);
}

.p-contextmenu {
    width: auto;
}

.bottombar-websocket-error.p-sidebar {
    height: 67px !important;
}

.bottombar-websocket-error.p-sidebar .p-sidebar-header {
    padding: unset;
    background: var(--red-color) !important;
}

.bottombar-websocket-error.p-sidebar .p-sidebar-content {
    background: var(--red-color) !important;
}

.bottombar-websocket-error.p-sidebar .p-sidebar-footer {
    padding: unset;
}

.pi-sort-alt:before {
    display: none;
    content: "\e99e";
}

.m-body .m-content.dashboard-view {
    margin-top: unset !important;
}

.view-site-header-title {
    color: var(--blue-color) !important;
    float: left;
    margin-top: 17px;
    font-size: 23px;
}

/*
.terminal-single-details-container .terminal-module>div {
    background-image: url(/../assets/app/media/img/icons/module-bg.png);
    width: 15%;
    height: 30%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;
}

.terminal-single-details-container .terminal-module>div span {
    top: 20%;
    font-size: 12px;
    font-weight: bold;
    cursor: default;
}

 */

.checkbox-address-container {
    margin: 8px 0 0 8px;
}

.p-inline-message.p-inline-message-error {
    background: transparent !important;
}

//Dashboard Downtime Threshold
.threshold-red.p-card.p-component {
    background-color: var(--red-color);
}

.threshold-green.p-card.p-component {
    background-color: var(--green-color);
}

.threshold-yellow.p-card.p-component {
    background-color: var(--yellow-color);
}

.p-tree {
    border: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: var(--orange-color);
    color: var(--text-color);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: var(--table-light);
    color: var(--text-color);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #495057 !important;
}

.icon-size-oversized .terminal {
    width: 300px;
    float: left;
    padding-right: 20px;

    height: 600px;
    position: relative;
    xoverflow: hidden;

    margin: 0px 50px;
}

.icon-size-oversized .terminal-module {
    right: 1px;
    top: 15%;
}

.feature-overview .p-col-3{
    padding-top:35px;
}

.p-breadcrumb {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 0rem 1rem;
}

terminal-filterbar {
    display:unset!important;
}

.change-view-style {
    background-color: var(--darkblue-color);
    /* right: 62px; */
    top: 34px;
    /* transform: translate(2%,-50%); */
    /* -webkit-transform: translate(2%,-50%); */
    /* position: absolute; */
    width: 144px;
    height: 45px;
    overflow: hidden;
    z-index: 2;
}
.change-view-style>div{
    float: left;
    height: 70%;
    width: 33.33%;
    background-size: 50% auto;
    background-repeat: no-repeat;
    background-position: center center;
    right: 0%;
    top: 50%;
    transform: translate(0%,-50%);
    -webkit-transform: translate(0%,-50%);
    position: relative;
    cursor: pointer;
    border-left: 1px solid var(--orange-color);
    border-color: var(--orange-color);
}

.change-view-style div:nth-child(2){
    border-right: 1px solid var(--orange-color);
}

.change-view-style>div:first-child{
    border-left: none;
}

.change-view-style .list-view{
    background-image: url(./assets/app/media/img/icons/grid-list.png);
}
.change-view-style .list-view.active,
.change-view-style .list-view:hover{
    background-image: url(./assets/app/media/img/icons/grid-list-active.png);
}

.change-view-style .grid-view{
    background-image: url(./assets/app/media/img/icons/grid-tile.png);
}

.change-view-style .grid-view.active,
.change-view-style .grid-view:hover{
    background-image: url(./assets/app/media/img/icons/grid-tile-active.png);
}

.change-view-style .fill-level-view{
    background-image: url(./assets/app/media/img/icons/stats-icon.png);
    background-size: 68%;
    /*filter: grayscale(1);
    cursor: default;
    opacity: 0.5;
    border-left: none;*/
}

.change-view-style .fill-level-view.active,
.change-view-style .fill-level-view:hover{
    background-image: url(./assets/app/media/img/icons/stats-icon-active.png);
}

.m-stack.m-stack--general {
    display: block!important;
    table-layout: fixed;
}

.m-brand {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    height: 70px;
    width: 255px;
    padding: 0px;
}
.m-brand .m-brand__tools .m-brand__icon {
    overflow: inherit;
    margin-left: 18px;
}

.dashboard-view.small .p-card .p-card-title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: unset;
}

.m-content.dashboard-view.small .presentable-content {
    font-size: 1vw;
    font-weight: bold;
    /* margin-bottom: 0.5em; */
}

.m-content.dashboard-view.small .presentable-content .pi-arrow-right.green {
    color:green;
    font-size: 1vw;
}

.m-content.dashboard-view.small .presentable-content .pi-arrow-left.red {
    color:red;
    font-size: 1vw;
}

.m-content.dashboard-view .presentable-content .pi-arrow-right.green {
    color:green;
    font-size: 1.5vw;
}

.m-content.dashboard-view .presentable-content .pi-arrow-left.red {
    color:red;
    font-size: 1.5vw;
}

.terminals-list-table .terminal-module {
    z-index:0;
}

form.m-login__form #selectedLanguage .p-dropdown {
    width:100%;
}

.dashboard-view .p-card.p-component {
    min-height: 100%;
}

#login-password .p-password .p-component{
    width:100%;
}

.terminals-list-table .terminal-module>div {
    background-position: center 4px!important;
}

.table-view.logs.transaction p-paginator{
    width:auto!important;
}

.p-datatable .p-datatable-tfoot > tr > td.tb-right{
    text-align: right!important;
}
.p-datatable .p-datatable-tfoot > tr > td.tb-center{
    text-align: center!important;
}
.p-datatable .p-datatable-tfoot > tr > td.tb-left{
    text-align: left!important;
}
.terminal-list-detail-dialog{
    height:100%!important;
}
.art-overview .p-card.p-component {
    min-height: 100%;
}
.art-overview .p-card .p-card-title {
    min-height: 100px;
}

terminal {
    height: 92%;
    display: block;
    width: 100%;
}

body .m-content.component-overview-view .p-toolbar-group-left {
    margin-left: 0px;
}

body .m-content.component-overview-view .p-toolbar-group-left .send-button.first{
    margin-left: 0px!important;
}

.p-datatable .p-datatable-header {
    padding: 0px 0px 10px 0px!important;
}

.external-link,
.external-link i.pi-external-link{
    color: var(--orange-color)!important;
    cursor: pointer;
}

.navigation-link{
    cursor: pointer;
}

body .p-inputgroup.password-group input {
    padding: 2px 2px 2px 10px!important;
}

.m-grid.m-grid--hor:not(.m-grid--desktop):not(.m-grid--desktop-and-tablet):not(.m-grid--tablet):not(.m-grid--tablet-and-mobile):not(.m-grid--mobile) {
    background-size: 103% auto!important;
    background-repeat: no-repeat;
    background-position: 73% 76%!important;
}

.terminal-state-bubble-inactive {
    width: 25px;
    height: 25px;
    background-color: grey;
    border: 1px solid grey;
    border-radius: 15px;
}

.terminal-state-bubble-error {
    width: 25px;
    height: 25px;
    background-color: var(--red-color);
    border: 1px solid var(--red-color);
    border-radius: 15px;
}

.terminal-state-bubble-success {
    width: 25px;
    height: 25px;
    background-color: var(--green-color);
    border: 1px solid var(--green-color);
    border-radius: 15px;
}

.terminal-state-background-success {
    text-align: center;
    width: fit-content;
    padding: 1px 6px;
    background-color: var(--green-color);
    border: 1px solid var(--green-color);
    border-radius: 15px;
}

.terminal-state-background-error  {
    text-align: center;
    width: fit-content;
    padding: 1px 6px;
    background-color: var(--red-color);
    border: 1px solid var(--red-color);
    border-radius: 15px;
}

.terminal-state-background-inactive {
    text-align: center;
    width: fit-content;
    padding: 1px 6px;
    background-color: grey;
    border: 1px solid grey;
    border-radius: 15px;
}

.terminal-state-background-blocked {
    text-align: center;
    width: fit-content;
    padding: 1px 6px;
    background-color: var(--purple-color)!important;
    border: 1px solid var(--purple-color)!important;
    border-radius: 15px;
}

.terminal-state-background-manufactured-blocked {
    text-align: center;
    width: fit-content;
    padding: 1px 6px;
    background-color: var(--yellow-color)!important;
    border: 1px solid var(--yellow-color)!important;
    border-radius: 15px;
    color: #000000! important;
}

.money-services-list .terminal-list .p-dropdown.p-component{
    width:100%!important;
}

.m-content.terminal-view-icon .p-scrollpanel-content,
.m-content.terminal-view-fill .p-scrollpanel-content{
    height: 100%!important;
}

.desktop terminal {
    height:100%;
}
.p-toolbar-group-left .p-checkbox.p-component{
    padding-top:5px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content{
    padding: 0px;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    padding-top: 5px;
}

.m-body .m-content.table-view form.terminal-edit > div{
    float: unset!important;
}

.p-treetable .p-treetable-scrollable-header, .p-treetable .p-treetable-scrollable-footer {
    background: unset;
}

.p-card-header{
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    padding: 1.25rem 1.25rem 0rem 1.25rem;
}

 tr.p-highlight td {
    background: var(--orange-color)!important;
}


.toast-message-error-modal.p-toast.p-component{
    background: var(--red-color);
}

.terminal .terminal-inner.out-of-service,
.terminal-list-entry td.out-of-service {
    background-color: var(--red-color)!important;
}

.terminal .terminal-inner.blocked,
.terminal-list-entry td.blocked {
    background-color: var(--purple-color)!important;
}

.terminal .terminal-inner.error,
.terminal-list-entry td.error {
    background-color: var(--purple-color)!important;
}

.terminal .terminal-inner.in-transaction,
.terminal-list-entry td.in-transaction {
    background-color: var(--green-color)!important;
}

.terminal .terminal-inner.manufactured-blocked,
.terminal-list-entry td.manufactured-blocked {
    background-color: var(--yellow-color)!important;
}

.terminal.no-art-connection .terminal-inner{
    background-color: rgba(163, 167, 169, 0.2)!important;
}

.terminal-list-entry  td.no-art-connection{
    background-color: rgba(163, 167, 169, 0.2)!important;
}

.terminal-list-entry  tr:nth-child(even) td.no-art-connection{
   // background-color: var(--table-dark)!important;
}

.p-yearpicker,
.p-monthpicker{
    background: var(--blue-color);
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight,
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight{
    background: var(--lightorange-color);
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover,
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover{
    background: var(--lightorange-color);
}
.calendar-footer {
    background: var(--lightblue-color);
    padding-bottom: 10px;
    padding-top: 10px;
}

.p-datepicker.p-component{
    width:450px!important;
}

.m-content .computer-registration .p-dropdown{
    width: 100%;
}

.m-content .computer-registration .calendar-input-field.p-calendar{
    width: 100%;
}

body .m-content .computer-registration .p-toolbar-group-left {
    margin-left: 0px;
}
body .m-content .computer-registration .p-toolbar.p-component {
    margin-left: -12px;
}

.send-button.single-icon .p-button.p-component {
    border-color: var(--orange-color) !important;
    background-color: var(--orange-color) !important;
}

.send-button.single-icon .p-button.p-button-icon-only {
    width: 2.5rem;
    padding: 0.15rem 0;
}
.terminal-detail-inner .p-toolbar-group-left{
    margin-left: 0px;
}
.terminal-detail-inner .calendar-input-field .p-inputtext.p-component{
    display: none;
}

#selectedLanguage .p-dropdown.p-component,
#selectedEntryPage .p-dropdown.p-component{
    width: 100%;
}
.p-inputtextarea {
    background-color: var(--blue-color);
    border: var(--blue-color);
}
// INCLUDE external css files

// include Menu-styles
@import "../src/css/menu.scss";
// include Buttons-styles
@import "../src/css/button.scss";


//FOR DESKTOP ONLY
/*
body.desktop .p-datatable .p-datatable-tbody > tr{
    line-height:0;
}
*/

.dashboard-view .p-card .p-card-content {
    padding: unset !important;
}

.dashboard-view .p-card .p-card-body {
    padding: 1.0rem !important;
}

.dashboard-view .p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: unset;
}

.art-details-edit-installation-dialog {
    width: 650px;
}

.installations-modes-dropdown .p-dropdown.p-component {
    width      : 230px !important;
}

p-card .header-maximize-button {
    position: absolute; right: 35px; top: 21px
}

.widget-auto-refresh-switcher {
    position: absolute;
    right: 50px;
    top: 5px;
}

.sidebar-settings .p-grid {
    margin-left: 10px;
}

.breadcrumb-content{
    margin-left: 22px;
}

.breadcrumb-content p-breadcrumb span{
    font-size: 22px;
}

.table-footer-filter span{
    margin: 0 0 0 12px ;
}

.details-header-icon {
    float: right;
    font-size: 1.3rem;
}
